import { DateTime } from 'luxon';
import { useState } from 'react';
import {
  FaCircleNotch,
  FaCompactDisc,
  FaPlayCircle,
  FaPlusSquare,
  FaRecordVinyl,
  FaSpinner,
} from 'react-icons/fa';
import api from '../../api';
import {
  BiEdit,
  BiGroup,
  BiMailSend,
  BiPlayCircle,
  BiPlus,
  BiTrash,
  BiUser,
  BiUserPlus,
} from 'react-icons/bi';
import {
  ITeam,
  ITeamInvite,
  ITeamMembership,
} from '../../api/services/team.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import TextEditDialog from '../shared/TextEditDialog';
import { ITrack } from '../../api/services/track.service';
import { IProject } from '../../api/services/project.service';
import { BsRecord } from 'react-icons/bs';
import { useStudioState } from '../studio/studioState';
import { globalThemeStyles } from '../../config/globalStyles';
import { usePlayer } from '../../hooks/usePlayer';

interface Props {
  className?: string;
}

export default function TrackSection({ className }: Props) {
  const { activeProjectTracks, activeProjectTracksLoading } = useStudioState();

  // function getToastErrorMessage(response: IValidationErrorResponse) {
  //   const violations = response.result;
  //   return (
  //     <div className='text-sm text-gray-700'>
  //       The following issues occured:
  //       <div className='space-y-3 pt-2'>
  //         {violations.map((t) => (
  //           <div key={t.objectId} className='space-y-2'>
  //             {/* {JSON.stringify(t)} */}
  //             {t.fieldName && (
  //               <div>
  //                 <b>{t.fieldName}</b> {t.message}
  //               </div>
  //             )}
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className='flex flex-col space-y-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <FaCompactDisc className='text-2xl text-indigo-500' />
            <div className='text-lg font-semibold'>Tracks</div>
          </div>
          <div
            className='cursor-pointer rounded-lg bg-indigo-500 p-1 px-2'
            // onClick={() => setIsMemberAddDialogOpen(true)}
          >
            <BiPlus className='text-xl text-white' />
          </div>
        </div>

        <dl className='flex h-120 w-full flex-col overflow-scroll'>
          {activeProjectTracksLoading && (
            <div className='flex items-center justify-center p-20 text-slate-500'>
              <FaSpinner size={30} className='animate-spin' />
            </div>
          )}
          {!activeProjectTracksLoading && activeProjectTracks.length > 0 && (
            <div className='flex flex-col space-y-4'>
              {activeProjectTracks.map((track, i) => (
                <TrackRow
                  key={i}
                  track={track}
                  deleteTrack={() => console.log('delete track')}
                  editTrack={() => console.log('edit track')}
                />
              ))}
            </div>
          )}
        </dl>
      </div>
    </>
  );
}

function TrackRow({
  track,
  editTrack,
  deleteTrack,
}: {
  track: ITrack;
  editTrack: (id: string) => void;
  deleteTrack: (id: string) => void;
}) {
  const { setUrl, setFileId, fileId, isPlaying, togglePlay, setTrack } =
    usePlayer();

  const [isLoadingUrl, setIsLoadingUrl] = useState(false);

  // const onClickPlay = () => {

  //   if (!file) return;
  //   setIsLoadingUrl(true);
  //   api.file
  //     .getFilePresignedDownloadUrl(file.id)
  //     .then((res) => {
  //       setUrl(res.data.result.presignedUrl);
  //       setFileId(file.id);
  //       setTrack(track);
  //     })
  //     .catch(() => {
  //       toast.error("Can't get link for artwork");
  //     })
  //     .finally(() => {
  //       setIsLoadingUrl(false);
  //     });
  // };
  return (
    <div
      key={track.id}
      className='relative flex h-24 overflow-hidden break-all rounded-lg bg-white py-2 px-4 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-800 md:h-20 lg:h-24 xl:h-16'
    >
      <div className='flex w-full items-center space-x-4'>
        <div
          onClick={() => console.log('play track')}
          className='flex rounded-md bg-slate-500 p-2 text-white '
        >
          <FaPlayCircle size={20} />
        </div>

        <div className='flex-1 '>
          <div className='flex items-center justify-between'>
            <h3 className='text-sm font-medium'>{track.name}</h3>
            <div className='flex space-x-8'>
              {/* <div
                className={`flex h-5 items-center justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${mapTrackTypeToColorClass(
                  track.createdBy
                )}`}
              >
                <div>READY</div>
              </div> */}
              <div className='flex space-x-2'>
                {/* <div onClick={() => editTrack(track.id)}>
                  <BiEdit size={20} />
                </div> */}
                <div onClick={() => deleteTrack(track.id)}>
                  {/* <div> */}
                  <BiTrash size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

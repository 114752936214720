import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api, { IPaginatedResponse } from '../api';
import { IProject } from '../api/services/project.service';
import NoTeamSection from '../components/dashboard/NoTeamSection';
import MidnightLogoViolet from '../components/global/midnight_violet.png';
import MidnightLogoYellow from '../components/global/midnight_yellow.png';
import { useGlobalState } from '../state';
import ActivitySection from '../components/dashboard/ActivitySection';
import { TrackWithFiles } from '../api/services/track.service';
import { ITeamMembership } from '../api/services/team.service';
import { INotification } from '../api/services/notification.service';
import {
  StudioStateProvider,
  useStudioState,
} from '../components/studio/studioState';
import TrackSection from '../components/submission/TrackSection';
import QuickActionSection from '../components/dashboard/QuickActionSection';
import { ArtistInput } from '../components/studio/sections/SubmissionInfo';
import ProjectMembersDialog from '../components/studio/dialogs/ProjectMembersDialog';

function Divider({ text }: { text: string }) {
  return (
    <div className='relative w-full'>
      <div className='absolute inset-0 flex items-center' aria-hidden='true'>
        <div className='w-full border-t border-slate-300 dark:border-slate-600' />
      </div>
      <div className='relative flex justify-center'>
        <span className='bg-slate-200 px-2 text-base text-slate-400 dark:bg-slate-700 dark:text-slate-500'>
          {text}
        </span>
      </div>
    </div>
  );
}

function SubmissionBody() {
  const { user, activeTeam } = useAuth();
  const { theme } = useGlobalState();
  let navigate = useNavigate();

  const [isModalOpenMember, setIsModalOpenMember] = useState(false);

  let { id } = useParams();

  const {
    // activeProjectRole,
    setActiveProjectId,
    activeProject,
    activeProjectTracks,
    activeProjectMembers,
    activeTrack,
    activeProjectInvites,
    activeFiles,
    refreshData,
    trackUpdateLoading,
    activeProjectLoading,
    setIsTrackValidationErrorsShown,
    isTourActive,
  } = useStudioState();

  useEffect(() => {
    setActiveProjectId(id!);
    // eslint-disable-next-line
  }, [id]);

  const [tracks, setTracks] = useState<IPaginatedResponse<TrackWithFiles[]>>();
  const [activities, setActivities] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      searchTracks();
      if (activeTeam.teamMembershipDTO?.role !== 'GUEST') {
        fetchActivities();
      }
    }
  }, [activeTeam]);

  async function fetchActivities() {
    if (activeProject === null) return;
    try {
      setLoading(true);
      const { data } = await api.notification.getNotificationsForProject(
        activeProject?.id,
        {
          size: 10,
          page: 0,
          // sort: 'createdAt,desc',
        }
      );
      if (data.result) {
        const act = data.result.content;
        //
        setActivities(act.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  async function searchTracks() {
    try {
      setLoading(true);
      const response = await api.track.searchTrack({
        size: 5,
      });
      if (response.data.result) {
        setTracks(response.data);
      }
      setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      <ProjectMembersDialog
        isOpen={isModalOpenMember}
        setIsOpen={setIsModalOpenMember}
        projectMembers={activeProjectMembers}
        projectInvites={activeProjectInvites}
        fetchProjectInvites={() => refreshData('activeProjectInvites')}
        fetchProjectMembers={() => refreshData('activeProjectMembers')}
      />
      {user && (
        <div className='flex grow flex-col items-center px-4 md:px-8 lg:px-16 xl:px-24'>
          {!activeTeam && (
            <div className='mt-16 w-full'>
              <NoTeamSection />
            </div>
          )}
          {activeTeam && (
            <>
              <div className=''>
                {theme === 'dark' ? (
                  <img
                    className='mt-4 h-16'
                    src={MidnightLogoYellow}
                    alt='MidnightLogoYellow'
                  />
                ) : (
                  <img
                    className='mt-4 h-16'
                    src={MidnightLogoViolet}
                    alt='MidnightLogoViolet'
                  />
                )}
              </div>
              <div className='mt-8 flex w-full items-center justify-between text-lg'>
                <div>button</div>
                <div className='flex flex-col'>
                  <div>{activeProject?.name}</div>
                  <div className='text-sm text-slate-400 dark:text-slate-500'>
                    <ArtistInput setIsModalOpenMember={setIsModalOpenMember} />
                  </div>
                </div>
                <div>button</div>
              </div>
            </>
          )}
          {activeTeam &&
            user &&
            activeTeam.teamMembershipDTO?.role !== 'GUEST' && (
              <div className='mt-16 grid w-full grid-cols-3 gap-6 lg:gap-16 xl:gap-16 2xl:gap-16'>
                <div className='col-span-full lg:col-span-1'>
                  {/* <QuickActionSection /> */}
                  <TrackSection className='mt-6' />
                </div>
                <div className='col-span-full space-y-12 lg:col-span-2 '>
                  {/* <QuickStatsSection
                    projects={projects}
                    tracks={tracks}
                    artists={artists}
                  /> */}
                  <ActivitySection activities={activities} className='mt-6' />
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
}

export default function Submission() {
  return (
    <StudioStateProvider>
      <SubmissionBody />
    </StudioStateProvider>
  );
}
